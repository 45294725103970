
import React from 'react';

const Workingarea = () => {
  const sections = [
    {
      title: "BRIDES WEDDING",
      color: "bg-green-500",
      imgSrc: "./img/about/wedding1.webp",
      alt: "Wedding Image",
      content: [
        "Our aim is to raise funds to support the families to get their daughters married off in order to save them from ailments like dowry and suicide due to lack of funds. Sankalp Social Trust is founded with the aim to help families get their daughters married off in a dignified way. We aim at wedding off poor girls and save poor people from dowry and other sacrifices. We want everyone to come together and perform poor girls' wedding so that she can lead her life happily."
      ]
    },
    {
      title: "FEEDING THE HUNGER",
      color: "bg-blue-500",
      imgSrc: "./img/about/food1.jpg",
      alt: "Food Image",
      content: [
        "Our aim is to feed the hungry homeless elderly, women, children, leprosy patients, and disabled on the streets as part of zero hunger.",
        "Weekly, 500 hungry homeless get food donations, fruits, and lunch. In India, many homeless people live in public places like highways, railway stations, and bus stops. Our goal is to feed the hungry homeless destitutes, abandoned senior citizens, and migrant workers.",
        "Sankalp Social Trust works to eradicate hunger, malnutrition, and food wastage. Donate today to help us serve the hungry homeless. In the coming days, we are planning to feed 500 hungry people daily. You can also become part of it by joining as volunteers and donors to spread smiles on hungry faces."
      ]
    },
    {
      title: "EDUCATION",
      color: "bg-teal-500",
      imgSrc: "./img/about/student2.jpg",
      alt: "Education Image",
      content: [
        "The literacy rate is increasing but the quality of education is reaching only those who can afford. It is not reaching ground level. The poor students who need quality education are becoming unemployed. In today's modern world, these students’ skills, logical thinking, and communication are not on par with students from financially strong backgrounds.",
        "As they do not have quality education, they are becoming incapable of competing with students from better educational institutions."
      ]
    },
    {
      title: "HOSPITALS",
      color: "bg-pink-500",
      imgSrc: "./img/about/health1.jpg",
      alt: "Health Image",
      content: [
        "Everyone's life is important. Due to financial constraints, the poor people suffer. It is reported that they also want to take services from super-speciality hospitals. No act of kindness, however small, is ever wasted. Sankalp Social Trust has helped many patients and become a helping hand to poor patients who are in need of services in time.",
        "Sankalp Social Trust believes it's not how much we give, but how much love we put into giving. Helping the poor is an honest service shown only by kind people. When we give cheerfully and accept gratefully, everyone is blessed. The best act in life is to serve the needy."
      ]
    },
    {
      title: "OLD AGE HOME",
      color: "bg-violet-500",
      imgSrc: "./img/about/oldage.webp",
      alt: "Old Age Home Image",
      content: [
        "Since ancient times, India had joint families where parents, grandparents, children, uncles, and cousins lived together. In today's world, due to urbanization and modernization, joint families are breaking down, and people are busy with their jobs, leaving their elderly members in old age homes.",
        "Sankalp Social Trust rescues elderly people, admitting them to old age homes. Many individuals, without family to care for them, admit themselves voluntarily. Sankalp aims to establish old age homes across India."
      ]
    },
    {
      title: "HANDICAPPED",
      color: "bg-cyan-500",
      imgSrc: "./img/about/handicap2.webp",
      alt: "Handicapped Image",
      content: [
        "As per the 2011 census, in India, about 2.68 crore people are disabled. This includes blindness, low vision, leprosy-cured persons, dwarfism, intellectual disability, mental illness, hearing impairment, acid attack victims, polio survivors, etc.",
        "Sankalp Social Trust is working to help these physically challenged individuals financially and physically. We have provided tri-cycles, artificial limbs, and paid medical bills to many victims. These individuals are suffering from hunger and the inability to buy medicines. Sankalp wants to serve them and needs volunteers and donors to fulfill their desires."
      ]
    }
  ];

  return (

    <>
    <h2 className="text-md text-center lg:text-xl font-bold text-gray-200 p-2 mt-10 bg-teal-700">Our Working Area</h2>
    <section className="py-16 bg-gray-50 px-2 lg:px-2">
      <div className="max-w-7xl mx-auto space-y-12">
        {sections.map((section, index) => (
          <div key={index} className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="flex flex-col lg:flex-row items-center p-2 lg:p-6 space-y-6 lg:space-y-0 lg:space-x-8">
              {/* Text Content */}
              <div className="lg:w-1/2">
                <h3 className={`lg:text-xl font-semibold text-white ${section.color} inline-block p-2 rounded-sm`}>
                  {section.title}
                </h3>
                {section.content.map((paragraph, idx) => (
                  <p key={idx} className="mt-2 text-md text-gray-800">
                    {paragraph}
                  </p>
                ))}
              </div>
              {/* Image */}
              <div className="lg:w-1/2">
                <img src={section.imgSrc} alt={section.alt} className="w-full rounded-lg shadow-md" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
    </>
  );
};

export default Workingarea;

