

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';


const teamMembers = [
  {
    name: 'Karan',
    role: 'Founder',
    image: '/img/about/karan.jpeg',
  },
  {
    name: 'Sachin',
    role: 'Team Leader',
    image: '/img/about/sachin.jpg',
  },
  {
    name: 'Chanda',
    role: 'Treasurer',
    image: '/img/about/chanda1.jpg',
  },
  {
    name: 'Siddharth',
    role: 'Chairman',
    image: '/img/about/pahuna.jpg',
  },
  {
    name: 'Krishna',
    role: 'Event Manager',
    image: '/img/about/krishna.jpg',
  },
  {
    name: 'Deepak',
    role: 'Co-Ordinator',
    image: '/img/about/deepak.jpg',
  },
];

const TeamComponent = () => {

    useEffect(() => {
        // Restore the scroll position
        const savedScrollPosition = sessionStorage.getItem('scrollPosition');
        if (savedScrollPosition) {
          window.scrollTo(0, savedScrollPosition);
        }
    
        // Cleanup and save scroll position when the component is unmounted
        const handleScroll = () => {
          sessionStorage.setItem('scrollPosition', window.scrollY);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
  return (
 

    <div className="max-w-6xl mx-auto p-4 ">
        
  <h1 className="text-xl font-bold text-center mb-8 text-gray-600">Meet Our Team</h1>
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
    {teamMembers.map((member, index) => (
      <Link to={`/team/details/${index}`} key={index} className="bg-gray-100 rounded-lg shadow-lg p-2 transition-transform ">
        <img src={member.image} alt={member.name} className="rounded-full w-40 h-40 mx-auto mb-4 border-4 border-teal-500" />
        <h2 className="text-2xl font-semibold text-center text-teal-600">{member.name}</h2>
        <p className="text-center font-semibold text-sm text-blue-400">{member.role}</p>
      </Link>
    ))}
  </div>
</div>

  );
};

export default TeamComponent;
