import React from 'react'

const Whoweare = () => {
  return (
    <section className="bg-gradient-to-r from-teal-600 to-blue-500 py-6 px-3 sm:px-4 lg:px-12 text-white">
      <div className="max-w-5xl mx-auto text-center space-y-8">
        <h2 className="text-2xl lg:text-3xl font-bold text-white">Who We Are</h2>
        <div className="space-y-6 text-lg">
          {/* <p>
          Sankalp Social Trust, a service-oriented non-governmental organization (NGO), was established in the year 2024 with the noble intentions of:
          </p> */}
          <p className="">
        Sankalp Social Trust is a service-oriented non-governmental organization (NGO) founded in 2024. Our mission is to uplift and empower underserved communities through acts of kindness, support, and resources. We aim to provide immediate relief and long-term solutions for individuals and families in need.
      </p>
          {/* <p>
            Taking up selfless activities in the duty of the motherland with the intention of giving back to society. The trust does not run on any aid from the government, and they have made it a point not to seek any grants.
          </p>
          <p>
            In a very short span of time, the trust earned a very good reputation. Sankalp Social Trust does not allow any political parties to take part in the trust's activities.
          </p> */}
        </div>
      </div>
    </section>
    
    
  )
}

export default Whoweare