// import React from 'react'
// const Footer = () => {
//   return (
//     <div className='flex flex-col justify-center items-center bg-gray-600 py-2 mt-4 text-gray-400 md:flex-row md:justify-around'>
    
//       <div>
//       <div className='flex justify-between items-center p-2'>
        
//         <a href="https://www.facebook.com/share/1Cin7yPnGJ" target='_blank'><img src="./img/icons/f.png" alt="logo" className='h-6 cursor-pointer mx-2' /></a>
//         <a href="https://www.youtube.com/@sankalpsocialtrust" target='_blank'><img src="./img/icons/y.png" alt="logo" className='h-6 cursor-pointer mx-2' /></a>
//        <a href=""> <img src="./img/icons/i.png" alt="logo" className='h-6 cursor-pointer mx-2' /></a>
//        <a href=""><img src="./img/icons/x.png" alt="logo" className='h-6 cursor-pointer mx-2' /></a>
       
        
//         </div>
//         <p className='text-xs text-center'> <a href="/privacy">All rights reserved Sankalp Social Trust</a></p>
//        <p className='text-xs text-center'> <a href="/" >www.sankalpsocialtrust.org</a></p>
//         <p className='text-xs mb-4 text-center'> <a href="/privacy" >Privacy Policy</a></p>
//       </div>
//       <div className='text-sm'>
//         <p className='text-center font-bold'>Registration Info.</p>
//         <p className='text-xs'>Registration Number : 202400933010232</p>
//         <p className='text-xs'>NGO Darpan Unique Id : UP/2024/0472580</p>
//         <p className='text-xs'>PAN Number : ABKTS4994E</p>
//       </div>
//       <div>
//        <p className='text-center font-bold'>Contact</p>
//        <p className='text-xs text-center'>Email:- sankalpsocialtrust.help@gmail.com</p>
//        <p className='text-xs text-center'>Mob:-  +91 8115784664</p>
       
       
//       </div>
//       <div className='text-sm'>
//         <p className='text-center font-bold'>Head Office</p>
//         <p className='text-xs'>Tetari Bazar Siddharth Nagar</p>
//         <p className='text-xs'>Uttar Pradesh Pincode-272207</p>
        
//       </div>

//     </div>
//   )
// }

// export default Footer
import React from 'react';

const Footer = () => {
  return (
    <div className="flex flex-col justify-center items-center bg-gray-800 py-6 mt-8 text-gray-300 md:flex-row md:justify-around md:py-8">
      <div className="text-center md:text-left mb-6 md:mb-0">
        <div className="flex justify-center md:justify-start items-center p-2 space-x-4">
          <a href="https://www.facebook.com/share/1Cin7yPnGJ" target="_blank" rel="noopener noreferrer">
            <img
              src="./img/icons/f.png"
              alt="Facebook"
              className="h-6 cursor-pointer transition-transform transform hover:scale-110"
            />
          </a>
          <a href="https://www.youtube.com/@sankalpsocialtrust" target="_blank" rel="noopener noreferrer">
            <img
              src="./img/icons/y.png"
              alt="YouTube"
              className="h-6 cursor-pointer transition-transform transform hover:scale-110"
            />
          </a>
          <a href="" target="_blank" rel="noopener noreferrer">
            <img
              src="./img/icons/i.png"
              alt="Instagram"
              className="h-6 cursor-pointer transition-transform transform hover:scale-110"
            />
          </a>
          <a href="" target="_blank" rel="noopener noreferrer">
            <img
              src="./img/icons/x.png"
              alt="X (formerly Twitter)"
              className="h-6 cursor-pointer transition-transform transform hover:scale-110"
            />
          </a>
        </div>
        <p className="text-xs mt-2">
          <a href="/privacy" className="hover:text-white">All rights reserved Sankalp Social Trust</a>
        </p>
        <p className="text-xs mt-1">
          <a href="/" className="hover:text-white">www.sankalpsocialtrust.org</a>
        </p>
        <p className="text-xs mb-4">
          <a href="/privacy" className="hover:text-white">Privacy Policy</a>
        </p>
      </div>

      <div className="text-center md:text-left mb-6 md:mb-0">
        <p className="text-sm font-semibold">Registration Info.</p>
        <p className="text-xs">Registration Number : 202400933010232</p>
        <p className="text-xs">NGO Darpan Unique Id : UP/2024/0472580</p>
        <p className="text-xs">PAN Number : ABKTS4994E</p>
      </div>

      <div className="text-center md:text-left mb-6 md:mb-0">
        <p className="text-sm font-semibold">Contact</p>
        <p className="text-xs">Email: sankalpsocialtrust.help@gmail.com</p>
        <p className="text-xs">Mob: +91 8115784664</p>
      </div>

      <div className="text-center md:text-left">
        <p className="text-sm font-semibold">Head Office</p>
        <p className="text-xs">Tetari Bazar Siddharth Nagar</p>
        <p className="text-xs">Uttar Pradesh Pincode-272207</p>
      </div>
    </div>
  );
};

export default Footer;
